@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Cedarville Cursive', cursive;
        font-family: 'Roboto', sans-serif;
    }
    body {
        overflow-y: scroll;
    }
}

@layer utilities {
    .animation-delay-2 {
        animation-delay: 2s;
    }
    .animation-delay-4 {
        animation-delay: 4s;
    }
    .animation-delay-6 {
        animation-delay: 6s;
    }
}
